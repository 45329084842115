var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c(
        "h3",
        { staticClass: "text-themecolor" },
        [
          _vm._v(_vm._s(_vm.trans("user.user")) + " \n            "),
          _vm.users
            ? _c("span", { staticClass: "card-subtitle" }, [
                _vm._v(
                  _vm._s(
                    _vm.trans("general.total_result_found", {
                      count: _vm.users.total
                    })
                  )
                )
              ])
            : _c("span", { staticClass: "card-subtitle" }, [
                _vm._v(_vm._s(_vm.trans("general.no_result_found")))
              ]),
          _vm._v(" "),
          _c("sort-by", {
            staticClass: "pull-right",
            attrs: {
              "order-by-options": _vm.orderByOptions,
              "sort-by": _vm.filterUserForm.sort_by,
              order: _vm.filterUserForm.order
            },
            on: {
              updateSortBy: function(value) {
                _vm.filterUserForm.sort_by = value
              },
              updateOrder: function(value) {
                _vm.filterUserForm.order = value
              }
            }
          }),
          _vm._v(" "),
          !_vm.showFilterPanel
            ? _c(
                "button",
                {
                  staticClass: "btn btn-info btn-sm pull-right m-r-10",
                  on: {
                    click: function($event) {
                      _vm.showFilterPanel = !_vm.showFilterPanel
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fas fa-filter" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "d-none d-sm-inline" }, [
                    _vm._v(_vm._s(_vm.trans("general.filter")))
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.users.total &&
          !_vm.showCreatePanel &&
          _vm.hasPermission("create-user")
            ? _c(
                "button",
                {
                  staticClass: "btn btn-info btn-sm pull-right m-r-10",
                  on: {
                    click: function($event) {
                      _vm.showCreatePanel = !_vm.showCreatePanel
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fas fa-plus" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "d-none d-sm-inline" }, [
                    _vm._v(_vm._s(_vm.trans("user.create_user")))
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-0" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("transition", { attrs: { name: "fade" } }, [
              _vm.showFilterPanel
                ? _c("div", { staticClass: "card border-bottom" }, [
                    _c("div", { staticClass: "card-body p-4" }, [
                      _c("h4", { staticClass: "card-title" }, [
                        _vm._v(_vm._s(_vm.trans("general.filter")))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-6 col-sm-3" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.name")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filterUserForm.name,
                                  expression: "filterUserForm.name"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { name: "name" },
                              domProps: { value: _vm.filterUserForm.name },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.filterUserForm,
                                    "name",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-6 col-sm-3" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.email")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filterUserForm.email,
                                  expression: "filterUserForm.email"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { name: "email" },
                              domProps: { value: _vm.filterUserForm.email },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.filterUserForm,
                                    "email",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-6 col-sm-3" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("role.role")))
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filterUserForm.role_id,
                                    expression: "filterUserForm.role_id"
                                  }
                                ],
                                staticClass: "custom-select col-12",
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.filterUserForm,
                                      "role_id",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v(
                                    _vm._s(_vm.trans("general.select_one"))
                                  )
                                ]),
                                _vm._v(" "),
                                _vm._l(_vm.roles, function(role) {
                                  return _c(
                                    "option",
                                    { domProps: { value: role.id } },
                                    [
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(role.name) +
                                          "\n                                          "
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-6 col-sm-3" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.status")))
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filterUserForm.status,
                                    expression: "filterUserForm.status"
                                  }
                                ],
                                staticClass: "custom-select col-12",
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.filterUserForm,
                                      "status",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v(
                                    _vm._s(_vm.trans("general.select_one"))
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  { attrs: { value: "activated" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.trans("user.status_activated"))
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  { attrs: { value: "pending_activation" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.trans(
                                          "user.status_pending_activation"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  { attrs: { value: "pending_approval" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.trans(
                                          "user.status_pending_approval"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "banned" } }, [
                                  _vm._v(
                                    _vm._s(_vm.trans("user.status_banned"))
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  { attrs: { value: "disapproved" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.trans("user.status_disapproved")
                                      )
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.showFilterPanel
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-danger btn-sm pull-right",
                              on: {
                                click: function($event) {
                                  _vm.showFilterPanel = !_vm.showFilterPanel
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.trans("general.cancel")))]
                          )
                        : _vm._e()
                    ])
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.hasPermission("create-user")
              ? _c("transition", { attrs: { name: "fade" } }, [
                  _vm.showCreatePanel
                    ? _c("div", { staticClass: "card border-bottom" }, [
                        _c("div", { staticClass: "card-body p-4" }, [
                          _c("h4", { staticClass: "card-title" }, [
                            _vm._v(_vm._s(_vm.trans("user.add_new_user")))
                          ]),
                          _vm._v(" "),
                          _c(
                            "form",
                            {
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.submit($event)
                                },
                                keydown: function($event) {
                                  return _vm.userForm.errors.clear(
                                    $event.target.name
                                  )
                                }
                              }
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12 col-sm-12" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-12 col-sm-3" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.trans("user.first_name")
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.userForm.first_name,
                                                  expression:
                                                    "userForm.first_name"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                value: "",
                                                name: "first_name",
                                                placeholder: _vm.trans(
                                                  "user.first_name"
                                                )
                                              },
                                              domProps: {
                                                value: _vm.userForm.first_name
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.userForm,
                                                    "first_name",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("show-error", {
                                              attrs: {
                                                "form-name": _vm.userForm,
                                                "prop-name": "first_name"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-12 col-sm-3" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.trans("user.last_name")
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.userForm.last_name,
                                                  expression:
                                                    "userForm.last_name"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                value: "",
                                                name: "last_name",
                                                placeholder: _vm.trans(
                                                  "user.last_name"
                                                )
                                              },
                                              domProps: {
                                                value: _vm.userForm.last_name
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.userForm,
                                                    "last_name",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("show-error", {
                                              attrs: {
                                                "form-name": _vm.userForm,
                                                "prop-name": "last_name"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-12 col-sm-3" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.trans("user.email")
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.userForm.email,
                                                  expression: "userForm.email"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                value: "",
                                                name: "email",
                                                placeholder: _vm.trans(
                                                  "user.email"
                                                )
                                              },
                                              domProps: {
                                                value: _vm.userForm.email
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.userForm,
                                                    "email",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("show-error", {
                                              attrs: {
                                                "form-name": _vm.userForm,
                                                "prop-name": "email"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-12 col-sm-3" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.trans("role.role"))
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("v-select", {
                                              attrs: {
                                                label: "name",
                                                "track-by": "id",
                                                name: "role_id",
                                                id: "role_id",
                                                options: _vm.roles,
                                                placeholder: _vm.trans(
                                                  "role.select_role"
                                                ),
                                                multiple: true,
                                                "close-on-select": false,
                                                "clear-on-select": false,
                                                "hide-selected": true,
                                                selected: _vm.selected_roles
                                              },
                                              on: {
                                                select: _vm.onRoleSelect,
                                                remove: _vm.onRoleRemove
                                              },
                                              model: {
                                                value: _vm.selected_roles,
                                                callback: function($$v) {
                                                  _vm.selected_roles = $$v
                                                },
                                                expression: "selected_roles"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("show-error", {
                                              attrs: {
                                                "form-name": _vm.userForm,
                                                "prop-name": "role_id"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-12 col-sm-3" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.trans("user.password")
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.userForm.password,
                                                  expression:
                                                    "userForm.password"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "password",
                                                value: "",
                                                name: "password",
                                                placeholder: _vm.trans(
                                                  "user.password"
                                                )
                                              },
                                              domProps: {
                                                value: _vm.userForm.password
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.userForm,
                                                    "password",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("show-error", {
                                              attrs: {
                                                "form-name": _vm.userForm,
                                                "prop-name": "password"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-12 col-sm-3" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.trans(
                                                      "user.password_confirmation"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.userForm
                                                      .password_confirmation,
                                                  expression:
                                                    "userForm.password_confirmation"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "password",
                                                value: "",
                                                name: "password_confirmation",
                                                placeholder: _vm.trans(
                                                  "user.password_confirmation"
                                                )
                                              },
                                              domProps: {
                                                value:
                                                  _vm.userForm
                                                    .password_confirmation
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.userForm,
                                                    "password_confirmation",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("show-error", {
                                              attrs: {
                                                "form-name": _vm.userForm,
                                                "prop-name":
                                                  "password_confirmation"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-info waves-effect waves-light pull-right",
                                  attrs: { type: "submit" }
                                },
                                [_vm._v(_vm._s(_vm.trans("general.add")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-danger waves-effect waves-light pull-right m-r-10",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      _vm.showCreatePanel = !_vm.showCreatePanel
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.trans("general.cancel")))]
                              )
                            ]
                          )
                        ])
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _vm.hasPermission("list-user") && _vm.users.total
                    ? _c("div", { staticClass: "table-responsive" }, [
                        _c("table", { staticClass: "table table-hover" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [
                                _vm._v(_vm._s(_vm.trans("user.name")))
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(_vm._s(_vm.trans("user.email")))
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(_vm._s(_vm.trans("role.role")))
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(_vm._s(_vm.trans("user.status")))
                              ]),
                              _vm._v(" "),
                              _c("th", { staticClass: "table-option" }, [
                                _vm._v(_vm._s(_vm.trans("general.action")))
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.users.data, function(user) {
                              return _c("tr", [
                                _c("td", {
                                  domProps: {
                                    textContent: _vm._s(
                                      user.profile.first_name +
                                        " " +
                                        user.profile.last_name
                                    )
                                  }
                                }),
                                _vm._v(" "),
                                _c("td", {
                                  domProps: { textContent: _vm._s(user.email) }
                                }),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "ul",
                                    {
                                      staticStyle: {
                                        "list-style": "none",
                                        padding: "0"
                                      }
                                    },
                                    _vm._l(user.roles, function(role) {
                                      return _c("li", [
                                        _vm._v(_vm._s(role.name_display))
                                      ])
                                    }),
                                    0
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  _vm._l(_vm.getUserStatus(user), function(
                                    status
                                  ) {
                                    return _c(
                                      "span",
                                      {
                                        class: [
                                          "label",
                                          "label-" + status.color,
                                          "m-r-5"
                                        ]
                                      },
                                      [_vm._v(_vm._s(status.label))]
                                    )
                                  }),
                                  0
                                ),
                                _vm._v(" "),
                                _c("td", { staticClass: "table-option" }, [
                                  _c(
                                    "div",
                                    { staticClass: "btn-group" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value: _vm.trans(
                                                "user.view_user"
                                              ),
                                              expression:
                                                "trans('user.view_user')"
                                            }
                                          ],
                                          staticClass: "btn btn-info btn-sm",
                                          attrs: { to: "/user/" + user.id }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-arrow-circle-right"
                                          })
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.hasPermission("delete-user")
                                        ? _c(
                                            "button",
                                            {
                                              directives: [
                                                {
                                                  name: "confirm",
                                                  rawName: "v-confirm",
                                                  value: {
                                                    ok: _vm.confirmDelete(user)
                                                  },
                                                  expression:
                                                    "{ok: confirmDelete(user)}"
                                                },
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value: _vm.trans(
                                                    "user.delete_user"
                                                  ),
                                                  expression:
                                                    "trans('user.delete_user')"
                                                }
                                              ],
                                              key: user.id,
                                              staticClass:
                                                "btn btn-danger btn-sm"
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fas fa-trash"
                                              })
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ])
                              ])
                            }),
                            0
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.users.total
                    ? _c(
                        "module-info",
                        {
                          attrs: {
                            module: "user",
                            title: "module_info_title",
                            description: "module_info_description",
                            icon: "users"
                          }
                        },
                        [
                          _c("div", { attrs: { slot: "btn" }, slot: "btn" }, [
                            _vm.hasPermission("create-user") &&
                            !_vm.showCreatePanel
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-info btn-sm",
                                    on: {
                                      click: function($event) {
                                        _vm.showCreatePanel = !_vm.showCreatePanel
                                      }
                                    }
                                  },
                                  [
                                    _c("i", { staticClass: "fas fa-plus" }),
                                    _vm._v(
                                      " " + _vm._s(_vm.trans("general.add_new"))
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("pagination-record", {
                    attrs: {
                      "page-length": _vm.filterUserForm.page_length,
                      records: _vm.users
                    },
                    on: {
                      "update:pageLength": function($event) {
                        return _vm.$set(
                          _vm.filterUserForm,
                          "page_length",
                          $event
                        )
                      },
                      "update:page-length": function($event) {
                        return _vm.$set(
                          _vm.filterUserForm,
                          "page_length",
                          $event
                        )
                      },
                      updateRecords: _vm.getUsers
                    }
                  })
                ],
                1
              )
            ])
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }