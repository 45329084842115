var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "wrapper" } }, [
    _c("div", { staticClass: "login-register" }, [
      _c(
        "div",
        { staticClass: "login-box card" },
        [
          _c("div", { staticClass: "card-body p-4" }, [
            _c("h3", { staticClass: "box-title m-b-20 text-center" }, [
              _vm._v(_vm._s(_vm.trans("passwords.reset_password")))
            ]),
            _vm._v(" "),
            !_vm.showMessage
              ? _c("div", [
                  _c(
                    "form",
                    {
                      staticClass: "form-horizontal form-material",
                      attrs: { id: "resetform" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.submit($event)
                        },
                        keydown: function($event) {
                          return _vm.resetForm.errors.clear($event.target.name)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "form-group " },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.resetForm.email,
                                expression: "resetForm.email"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "email",
                              placeholder: _vm.trans("auth.email")
                            },
                            domProps: { value: _vm.resetForm.email },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.resetForm,
                                  "email",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.resetForm,
                              "prop-name": "email"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.resetForm.password,
                                expression: "resetForm.password"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "password",
                              name: "password",
                              placeholder: _vm.trans("auth.password")
                            },
                            domProps: { value: _vm.resetForm.password },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.resetForm,
                                  "password",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.resetForm,
                              "prop-name": "password"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.resetForm.password_confirmation,
                                expression: "resetForm.password_confirmation"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "password",
                              name: "password",
                              placeholder: _vm.trans("auth.confirm_password")
                            },
                            domProps: {
                              value: _vm.resetForm.password_confirmation
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.resetForm,
                                  "password_confirmation",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.resetForm,
                              "prop-name": "password_confirmation"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group text-center m-t-20" },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light",
                              attrs: { type: "submit" }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.trans("passwords.reset_password"))
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ])
              : _c("div", { staticClass: "text-center" }, [
                  _vm.status
                    ? _c("h4", {
                        staticClass: "alert alert-success",
                        domProps: { textContent: _vm._s(_vm.message) }
                      })
                    : _c("h4", {
                        staticClass: "alert alert-danger",
                        domProps: { textContent: _vm._s(_vm.message) }
                      })
                ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group m-b-0" }, [
              _c("div", { staticClass: "col-sm-12 text-center" }, [
                _c(
                  "p",
                  [
                    _vm._v(_vm._s(_vm.trans("auth.back_to_login?")) + " "),
                    _c(
                      "router-link",
                      {
                        staticClass: "text-info m-l-5",
                        attrs: { to: "/login" }
                      },
                      [_c("b", [_vm._v(_vm._s(_vm.trans("auth.sign_in")))])]
                    )
                  ],
                  1
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("guest-footer")
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }